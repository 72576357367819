const createBooking = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      
      if (data.status === 'ok') {
        return data;
      } else {
        throw new Error('Failed to create booking');
      }
    } catch (error) {
      console.error('Error creating booking:', error);
      throw error;
    }
  };
  
  export { createBooking };