import React from 'react';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();
  
  return (
    <div className="home-container">
      <div className="container">
        <h1>{t('home.title')}</h1>
        <div className="subtitle">
          {t('home.subtitle')}
        </div>
        <div className="chat-bubble">
          {t('home.greeting')}
        </div>
        <div className="chat-animation-container">
          <div className="typing-animation">
            <div className="chat-cloud">
              <div className="typing-dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="features">
          <div className="feature">
            <h3>{t('home.features.ai.title')}</h3>
            <p>{t('home.features.ai.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('home.features.automation.title')}</h3>
            <p>{t('home.features.automation.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('home.features.multiplatform.title')}</h3>
            <p>{t('home.features.multiplatform.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('home.features.personalization.title')}</h3>
            <p>{t('home.features.personalization.description')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;