import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../utils/auth';
import { authService } from '../services/api';
import '../assets/css/dashboard.css';

const Dashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      let isMounted = true;
      
      const fetchUserData = async () => {
        try {
          const data = await authService.getUserProfile();
          console.log('Dashboard received data:', data);
          
          // Перевіряємо чи є помилка unauthorized
          if (data.error === 'unauthorized') {
            console.log('User is unauthorized, redirecting to login');
            handleLogout();
            return;
          }

          if (isMounted) {
            setUserData(data);
            setLoading(false);
          }
        } catch (err) {
          console.error('Dashboard error:', err);
          if (isMounted) {
            setError(err.message);
            setLoading(false);
          }
        }
      };
  
      fetchUserData();

      return () => {
        isMounted = false;
      };
    }, []);
  
    const handleLogout = () => {
      console.log('Logging out...');
      removeToken();
      navigate('/login', { replace: true });
    };
  
    const handleRetry = () => {
      setLoading(true);
      setError(null);
      // Повторний запит даних
      authService.getUserProfile()
        .then(data => {
          if (data.error === 'unauthorized') {
            handleLogout();
            return;
          }
          setUserData(data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });
    };
  
    if (loading) {
      return (
        <div className="dashboard loading">
          <div className="spinner"></div>
          <p>{t('dashboard.loading')}</p>
        </div>
      );
    }
  
    if (error) {
      return (
        <div className="dashboard error">
          <h2>{t('dashboard.error')}</h2>
          <p>{error}</p>
          <div className="error-actions">
            <button onClick={handleRetry} className="retry-button">
              {t('dashboard.retry')}
            </button>
            <button onClick={handleLogout} className="logout-button">
              {t('dashboard.back_to_login')}
            </button>
          </div>
        </div>
      );
    }
  
    return (
      <div className="dashboard-container">
        {userData && (
          <div className="dashboard-content">
            <div className="welcome-section">
              <h2>{t('dashboard.welcome')}, {userData.fullName || userData.email}</h2>
            </div>
  
            <div className="dashboard-grid">
              <div className="dashboard-card">
                <h3>{t('dashboard.personal_info')}</h3>
                <div className="info-item">
                  <span className="info-label">{t('dashboard.name')}:</span>
                  <span className="info-value">{userData.fullName || '-'}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">{t('dashboard.email')}:</span>
                  <span className="info-value">{userData.email}</span>
                </div>
              </div>
  
              <div className="dashboard-card">
                <h3>{t('dashboard.account_info')}</h3>
                <div className="info-item">
                  <span className="info-label">{t('dashboard.roles')}:</span>
                  <span className="info-value">
                    {userData.roles && userData.roles.map(role => (
                      <span key={role} className="role-badge">
                        {role.replace('ROLE_', '')}
                      </span>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default Dashboard;