import { fetchWithAuth, removeToken } from '../utils/auth';

const API_URL = process.env.REACT_APP_API_URL;

export const authService = {
  login: async (credentials) => {
    try {
      console.log('Login API URL:', `${API_URL}/v1/login`);
      const response = await fetch(`${API_URL}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      console.log('Login response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Login error:', errorText);
        throw new Error('Authentication failed');
      }

      return response.json();
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },
  
  getUserProfile: async () => {
    try {
      console.log('Fetching user profile from:', `${API_URL}/v1/me`);
      const response = await fetchWithAuth(`${API_URL}/v1/me`);
      
      console.log('User profile response:', response);
      
      // Якщо маємо 401, виконуємо logout через компонент, а не через redirect
      if (response.status === 401) {
        console.log('Unauthorized in getUserProfile');
        return { error: 'unauthorized' };
      }

      if (!response.ok) {
        console.error('Failed to fetch user data:', response);
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      console.log('User data received:', data);
      return data;
    } catch (error) {
      console.error('getUserProfile error:', error);
      throw error;
    }
  }
};