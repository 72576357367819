import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { isAuthenticated, removeToken } from '../utils/auth';

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authenticated = isAuthenticated();
  
  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };
  
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">{t('header.logo')}</Link>
        <div className="header-right">
          <LanguageSwitcher />
          
          {authenticated ? (
            <>
              <Link to="/dashboard" className="dashboard-btn">
                {t('dashboard.title')}
              </Link>
              <button onClick={handleLogout} className="logout-btn">
                {t('dashboard.logout')}
              </button>
            </>
          ) : (
            <Link to="/login" className="login-btn">
              {t('header.login')}
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;