import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './pages/Login';
import BookingPage from './pages/Booking';
import Dashboard from './pages/Dashboard';
import NetworkDiagnostic from './components/NetworkDiagnostic';
import PrivateRoute from './components/PrivateRoute';

// Import CSS
import './assets/css/main.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/typing-animation.css';
import './assets/css/login.css';
import './assets/css/error.css';
import './assets/css/booking.css'; 

function App() {
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    setAppReady(true);
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL || '/'}>
      <Suspense fallback={<div>Loading application...</div>}>
        {appReady ? (
          <div className="App">
            <Header />
            <main className="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
            {process.env.NODE_ENV !== 'production' && <NetworkDiagnostic />}
          </div>
        ) : (
          <div className="loading-app">Initializing application...</div>
        )}
      </Suspense>
    </Router>
  );
}

// 404 Page
function NotFound() {
  const { t } = useTranslation();
  
  return (
    <div className="error-container">
      <h1>{t('error.404.heading')}</h1>
      <p>{t('error.404.message')}</p>
      <Link to="/" className="back-home-btn">
        {t('error.404.back_home')}
      </Link>
    </div>
  );
}

export default App;