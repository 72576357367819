import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../utils/auth';
import { authService } from '../services/api';

export const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
        const data = await authService.login({ username, password });
        // Зберігаємо токен
        setToken(data.token);
        
        // Перенаправляємо на дашборд
        navigate('/dashboard');
      } catch (err) {
        setError(t('login.login_error'));
      } finally {
        setLoading(false);
      }
    };

  return (
    <div className="login-form-container">
      
      {success ? (
        <div className="success-message">
          <p>{t('login.login_success')}</p>
          navigate('/dashboard');
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="username">{t('login.email')}</label>
            <input
              type="email"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('login.password')}</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" disabled={loading}>
            {loading ? 'Loading...' : t('login.submit')}
          </button>
        </form>
      )}
      
      <div className="login-links">
        <a href="/">{t('login.back_home')}</a>
      </div>
    </div>
  );
};