import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/css/contacts.css';

function Contact() {
  const { t } = useTranslation();
  
  useEffect(() => {
    // Display phone with delay and obfuscation
    setTimeout(function() {
      const phoneEl = document.getElementById('phone');
      if (phoneEl) {
        const phoneDigits = ['3','8','0','9','3','7','4','0','5','3','2','0'];
        phoneEl.textContent = '+' + phoneDigits.join('');
      }
    }, 500);

    // Display email with delay and obfuscation
    setTimeout(function() {
      const emailEl = document.getElementById('email');
      if (emailEl) {
        const emailParts = ['support', 'charychat.com'];
        emailEl.textContent = emailParts[0] + '@' + emailParts[1];
      }
    }, 800);
  }, []);
  
  return (
    <div className="contact-container">
      <div className="contact-content">
        <h1>{t('contacts.title')}</h1>

        <section className="contact-section">
          <h2>{t('contacts.entrepreneur')}</h2>
          <p>{t('contacts.name')}</p>
        </section>

        <section className="contact-section">
          <h2>{t('contacts.address')}</h2>
          <p>{t('contacts.address_text')}</p>
        </section>

        <section className="contact-section">
          <h2>{t('contacts.phone')}</h2>
          <p><span id="phone">{t('contacts.phone_placeholder')}</span></p>
        </section>

        <section className="contact-section">
          <h2>{t('contacts.email')}</h2>
          <p><span id="email">{t('contacts.email_placeholder')}</span></p>
        </section>
      </div>
    </div>
  );
}

export default Contact;