import React from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/css/privacy.css';

function PrivacyPolicy() {
  const { t } = useTranslation();
  
  return (
    <div className="policy-container">
      <h1>{t('privacy.title')}</h1>

      <h2>{t('privacy.intro.title')}</h2>
      <p>{t('privacy.intro.description')}</p>

      <h2>{t('privacy.collection.title')}</h2>
      <p>{t('privacy.collection.description')}</p>
      <ul>
        <li>{t('privacy.collection.account')}</li>
        <li>{t('privacy.collection.usage')}</li>
        <li>{t('privacy.collection.device')}</li>
        <li>{t('privacy.collection.cookies')}</li>
      </ul>

      <h2>{t('privacy.usage.title')}</h2>
      <p>{t('privacy.usage.description')}</p>
      <ul>
        <li>{t('privacy.usage.improvement')}</li>
        <li>{t('privacy.usage.personalization')}</li>
        <li>{t('privacy.usage.notifications')}</li>
        <li>{t('privacy.usage.security')}</li>
      </ul>

      <h2>{t('privacy.sharing.title')}</h2>
      <p>{t('privacy.sharing.description')}</p>
      <ul>
        <li>{t('privacy.sharing.partners')}</li>
        <li>{t('privacy.sharing.legal')}</li>
        <li>{t('privacy.sharing.third_party')}</li>
      </ul>

      <h2>{t('privacy.security.title')}</h2>
      <p>{t('privacy.security.description')}</p>

      <h2>{t('privacy.rights.title')}</h2>
      <p>{t('privacy.rights.description')}</p>
      <ul>
        <li>{t('privacy.rights.access')}</li>
        <li>{t('privacy.rights.update')}</li>
        <li>{t('privacy.rights.delete')}</li>
        <li>{t('privacy.rights.restrict')}</li>
      </ul>

      <h2>{t('privacy.changes.title')}</h2>
      <p>{t('privacy.changes.description')}</p>

      <h2>{t('privacy.contact.title')}</h2>
      <p>{t('privacy.contact.description')}</p>
    </div>
  );
}

export default PrivacyPolicy;