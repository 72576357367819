import React from 'react';
import BookingForm from '../components/booking/BookingForm';

const BookingPage = () => {
  return (
    <div className="booking-page">
      <h1>Бронювання</h1>
      <BookingForm />
    </div>
  );
};

export default BookingPage;