import React from 'react';
import { createBooking } from '../../api/booking/booking.api';

const BookingForm = () => {
  const handleBooking = async () => {
    try {
      const result = await createBooking();
    } catch (error) {
      console.error('Booking error:', error);
    }
  };

  return (
    <div className="booking-form">
      <button 
        onClick={handleBooking}
        className="booking-button"
      >
        Створити бронювання
      </button>
    </div>
  );
};

export default BookingForm;