import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  
  return (
    <footer className="footer">
      <div className="footer-content">
        <div>
          <h3>{t('footer.about')}</h3>
          <ul className="footer-links">
            <li><Link to="/">{t('header.home')}</Link></li>
            <li><Link to="/contact">{t('footer.contacts')}</Link></li>
            <li><Link to="/privacy">{t('footer.privacy')}</Link></li>
          </ul>
        </div>
        <div>
          <h3>{t('footer.contacts')}</h3>
          <ul className="footer-links">
            <li><a href="mailto:support@charychat.com">support@charychat.com</a></li>
          </ul>
        </div>
        <div>
          <h3>{t('footer.social')}</h3>
          <ul className="footer-links">
            <li><a href="https://www.instagram.com/oleh_vitv" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="https://www.facebook.com/profile.php?id=61574119740061" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} CharyChat. {t('footer.rights')}</p>
        <div className="developer-info">
          {t('footer.developed_by')} <a href="https://www.linkedin.com/in/oleh-vitvitskyi-22023514b/" target="_blank" rel="noopener noreferrer">Oleh Vitvitskyi</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;