// Отримання токену з localStorage
export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => {
    if (!token) {
      console.error('Attempted to set empty token');
      return false;
    }
    try {
      localStorage.setItem('token', token);
      return true;
    } catch (e) {
      console.error('Error setting token:', e);
      return false;
    }
  };

// Видалення токену з localStorage
export const removeToken = () => localStorage.removeItem('token');

export const isAuthenticated = () => {
    const token = getToken();
    return !!token && token.length > 10;
  };

// Отримання заголовків Authorization
export const getAuthHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};


export const fetchWithAuth = async (url, options = {}) => {
    try {
      const token = getToken();
      if (!token) {
        // Якщо токену немає, вважаємо користувача неавторизованим
        throw new Error('No authentication token found');
      }
  
      const headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
  
      const response = await fetch(url, {
        ...options,
        headers,
      });
  
      // Додаємо логування для відстеження відповіді
      console.log('API Response Status:', response.status);
      
      // Якщо статус 401, обробляємо це як неавторизований доступ
      if (response.status === 401) {
        console.log('Unauthorized access, logging out');
        // Не викликаємо негайно window.location.href = '/login'
        // Це може призводити до проблем з циклічними перенаправленнями
        removeToken();
        return { ok: false, status: 401, message: 'Unauthorized' };
      }
  
      return response;
    } catch (error) {
      console.error('Fetch error:', error);
      return { 
        ok: false, 
        status: 0, 
        message: error.message,
        json: async () => ({ error: error.message })
      };
    }
  };