import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '../components/LoginForm';
import '../assets/css/login.css'; 

function Login() {
  const { t } = useTranslation();

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>{t('login.title')}</h1>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;