import React, { useState, useEffect } from 'react';

function NetworkDiagnostic() {
  const [diagnosticResults, setDiagnosticResults] = useState({
    navigatorOnline: null,
    loadTime: null,
    envVariables: {},
    errors: []
  });

  useEffect(() => {
    const runDiagnostics = async () => {
      const startTime = performance.now();
      
      const results = {
        navigatorOnline: navigator.onLine,
        loadTime: null,
        envVariables: {
          NODE_ENV: process.env.NODE_ENV,
          PUBLIC_URL: process.env.PUBLIC_URL,
          REACT_APP_API_URL: process.env.REACT_APP_API_URL
        },
        errors: []
      };

      // Simulate API check
      try {
        // Add a fallback if you can't reach your actual API
        await fetch('https://httpstat.us/200', { 
          method: 'GET',
          mode: 'no-cors', // This is important for CORS issues
          timeout: 5000
        });
      } catch (error) {
        results.errors.push({
          type: 'network',
          message: error.message
        });
      }

      // Calculate load time
      results.loadTime = (performance.now() - startTime).toFixed(2) + 'ms';
      
      setDiagnosticResults(results);
    };

    runDiagnostics();
  }, []);

  if (!diagnosticResults.navigatorOnline && diagnosticResults.navigatorOnline !== null) {
    return (
      <div className="diagnostic-error">
        <h2>Network Error</h2>
        <p>You appear to be offline. Please check your internet connection.</p>
      </div>
    );
  }

  return (
    <div className="network-diagnostic" style={{ 
      position: 'fixed', 
      bottom: '10px', 
      right: '10px',
      background: '#f5f5f5',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 0 5px rgba(0,0,0,0.2)',
      fontSize: '12px',
      zIndex: 9999
    }}>
      <h4>Diagnostic Info</h4>
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        <li>Online: {diagnosticResults.navigatorOnline ? 'Yes' : 'No'}</li>
        <li>Load Time: {diagnosticResults.loadTime}</li>
        <li>Environment: {diagnosticResults.envVariables.NODE_ENV}</li>
        <li>PUBLIC_URL: {diagnosticResults.envVariables.PUBLIC_URL || 'not set'}</li>
        <li>API URL: {diagnosticResults.envVariables.REACT_APP_API_URL || 'not set'}</li>
      </ul>
      {diagnosticResults.errors.length > 0 && (
        <div>
          <h5>Errors:</h5>
          <ul>
            {diagnosticResults.errors.map((error, index) => (
              <li key={index}>{error.type}: {error.message}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default NetworkDiagnostic;